import orderBy from 'lodash/orderBy';
import { useRouter } from 'next/router';
import { useCurrentEvent } from './useCurrentEvent';

// eslint-disable-next-line import/prefer-default-export
export function useCurrentEventDay() {
  const router = useRouter();
  const [event] = useCurrentEvent();
  const { dayindex } = router.query;
  const days = orderBy(event?.attributes?.days?.data ?? [], [
    'attributes.date',
  ]);

  if (days.length === 0) {
    return null;
  }

  return days[dayindex];
}
