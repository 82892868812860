import { useSelector } from 'react-redux';
import { selectCurrentTenant } from '@slices/tenant';
import Image from 'next/image';
import { DEFAULT_LOGO } from '@config';
import PropTypes from 'prop-types';

function Logo(props) {
  const {
    variant, width, height, sx,
  } = props;
  const tenant = useSelector(selectCurrentTenant);
  const imageVariant = variant === 'light' ? 'brandLogoLight' : 'brandLogoDark';
  const image = tenant?.attributes[imageVariant].data?.attributes.formats?.medium?.url
    ?? tenant?.attributes[imageVariant].data?.attributes.formats?.small?.url
    ?? tenant?.attributes[imageVariant].data?.attributes.formats?.thumbnail?.url
    ?? tenant?.attributes[imageVariant].data?.attributes.url
    ?? DEFAULT_LOGO[variant];

  return (
    <Image
      alt="logo"
      height={height ?? 100}
      src={image}
      style={{
        objectFit: 'contain',
        ...sx,
      }}
      width={width ?? 100}
    />
  );
}

Logo.defaultProps = {
  variant: 'light',
};

Logo.propTypes = {
  variant: PropTypes.oneOf(['light', 'dark']),
};

export default Logo;
