import { combineReducers } from '@reduxjs/toolkit';
import tenantReducer from '@slices/tenant';
import navbarTitleReducer from '@slices/navbar-title';
import feedbackReducer from '@modules/events/state/slices/feedback';

export const rootReducer = combineReducers({
  tenant: tenantReducer,
  navbarTitle: navbarTitleReducer,
  feedback: feedbackReducer,
});
