import PropTypes from 'prop-types';
import { useModuleAccessibility } from '@modules/event/hooks';
import AuthAclGuard from '@components/AuthAclGuard';

const REQUIRED_ACL = [
  'api::event-module-setting.event-module-setting.update',
];

export default function MenuItemAccessibilityGuard({ name, children }) {
  const [accessibility] = useModuleAccessibility(name);

  if (accessibility === 'inaccessible') {
    return (
      <AuthAclGuard
        requiredAcl={REQUIRED_ACL}
      >
        {children}
      </AuthAclGuard>
    );
  }

  if (accessibility === 'disabled') {
    return null;
  }

  return children;
}

MenuItemAccessibilityGuard.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};

MenuItemAccessibilityGuard.defaultProps = {};
