import getConfig from 'next/config';
import { useSession } from 'next-auth/react';
import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';

const { publicRuntimeConfig } = getConfig();

export default function useDownloadFile() {
  const { data: session } = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);

  const handler = useCallback(async (url, fileName, isRelative = true, successMessage = null) => {
    try {
      setLoading(true);
      const fileUrl = isRelative ? `${publicRuntimeConfig.API_ENDPOINT}${url}` : url;
      const response = await fetch(fileUrl, {
        headers: {
          Authorization: `Bearer ${session.accessToken}`,
        },
      });

      const blob = await response.blob();
      const objectUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      document.body.appendChild(anchor);

      anchor.href = objectUrl;
      anchor.download = fileName;
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
      anchor.remove();

      enqueueSnackbar(successMessage ?? 'Datei heruntergeladen', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [
    session.accessToken,
    enqueueSnackbar,
    setLoading,
  ]);

  return [isLoading, handler];
}
