import resources from './i18n';

export const rootPath = '';

const config = {
  name: 'hubspot',
  rootPath,
  i18n: {
    resources,
  },
  menu: {
    getSections: (t) => [],
  },
};

export default config;
