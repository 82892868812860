import { useRouter } from 'next/router';

import { useCurrentEvent } from './useCurrentEvent';

export function useEventAccessibility() {
  const router = useRouter();
  const [event, _, { loading }] = useCurrentEvent();

  if (!loading && !event) {
    router.push('/404');
  }
}
