import { FiSettings } from 'react-icons/fi';
import { LiaExchangeAltSolid } from 'react-icons/lia';

import resources from './i18n';

export const rootPath = '/admin';

const config = {
  name: 'admin',
  rootPath,
  i18n: {
    resources,
  },
  modules: {
    ComponentTenantModuleAdmin: {
    },
    ComponentTenantModuleAdminDataExchange: {
    },
  },
  menu: {
    getSections: (t) => [
      {
        title: t('modules.admin.title'),
        acl: ['api::tenant.tenant.update'],
        items: [
          /* {
            title: t('modules.admin.menu.dashboard'),
            path: `${rootPath}`,
            type: [
              'ComponentTenantModuleAdmin',
              'ComponentTenantModuleEventBasic',
            ],
            icon: <FiGrid />,
            acl: ['api::tenant.tenant.update'],
          },
          {
            title: t('modules.admin.menu.locations'),
            path: `${rootPath}/locations`,
            type: [
              'ComponentTenantModuleAdmin',
              'ComponentTenantModuleEventBasic',
            ],
            icon: <FiHome />,
            acl: ['api::branch.location.update'],
            dashboardTitle: t('modules.admin.dashboard.locations.title'),
            dashboardDescription: t('modules.admin.dashboard.locations.description'),
          },
          {
            title: t('modules.admin.menu.rooms'),
            path: `${rootPath}/rooms`,
            type: [
              'ComponentTenantModuleAdmin',
              'ComponentTenantModuleEventBasic',
            ],
            icon: <FiBox />,
            acl: ['api::branch.room.update'],
            dashboardTitle: t('modules.admin.dashboard.rooms.title'),
            dashboardDescription: t('modules.admin.dashboard.rooms.description'),
          },
          {
            title: t('modules.admin.menu.users'),
            path: `${rootPath}/users`,
            type: [
              'ComponentTenantModuleAdmin',
              'ComponentTenantModuleEventBasic',
            ],
            icon: <FiUsers />,
            acl: [
              'plugin::users-permissions.user.find',
              'plugin::users-permissions.user.findOne',
              'plugin::users-permissions.user.create',
              'plugin::users-permissions.user.update',
              'plugin::users-permissions.user.destroy',
            ],
            dashboardTitle: t('modules.admin.dashboard.users.title'),
            dashboardDescription: t('modules.admin.dashboard.users.description'),
          }, */
          {
            module: 'adminDataExchange',
            title: t('modules.admin.menu.data-exchange'),
            path: `${rootPath}/data-exchange`,
            type: 'ComponentTenantModuleAdminDataExchange',
            icon: <LiaExchangeAltSolid />,
            acl: ['api::tenant.tenant.update'],
            dashboardTitle: t('modules.admin.dashboard.data-exchange.title'),
            dashboardDescription: t('modules.admin.dashboard.data-exchange.description'),
          },
          {
            module: 'adminSettings',
            title: t('modules.admin.menu.settings'),
            path: `${rootPath}/settings`,
            type: 'ComponentTenantModuleAdmin',
            icon: <FiSettings />,
            acl: ['api::tenant.tenant.update'],
            dashboardTitle: t('modules.admin.dashboard.settings.title'),
            dashboardDescription: t('modules.admin.dashboard.settings.description'),
          },
        ],
      },
    ],
  },
};

export default config;
