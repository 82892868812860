import { selectCurrentTenant } from '@slices/tenant';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

/**
 *
 * @returns {*}
 */
export default function useTenant() {
  const tenant = useSelector(selectCurrentTenant);
  return useMemo(() => ({
    tenant,
    hasTenantModule: (modules) => {
      if (!Array.isArray(modules)) {
        // eslint-disable-next-line no-param-reassign
        modules = [modules];
      }

      return modules.filter((m) => tenant.attributes.modules
        // eslint-disable-next-line no-underscore-dangle
        .find((tm) => m === tm.__typename)).length === modules.length;
    },
  }), [tenant]);
}
