export default function AsanaIcon() {
  return (
    <svg
      height="1em"
      stroke="currentColor"
      version="1.1"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M18.2,12.6c-2.6,0-4.7,2.1-4.7,4.7c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7C22.9,14.7,20.8,12.6,18.2,12.6L18.2,12.6z M5.8,12.6c-2.6,0-4.7,2.1-4.7,4.7c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7C10.6,14.7,8.5,12.6,5.8,12.6L5.8,12.6z M16.7,6.7c0,2.6-2.1,4.7-4.7,4.7c-2.6,0-4.7-2.1-4.7-4.7C7.3,4,9.4,1.9,12,1.9C14.6,1.9,16.7,4,16.7,6.7L16.7,6.7z"
          strokeWidth={2.5}
        />
      </g>
    </svg>
  );
}
