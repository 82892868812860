import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tenant: null,
};

const slice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setTenant(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.tenant = action.payload;
    },
  },
});

export const { setTenant } = slice.actions;

export const selectCurrentTenant = (state) => state.tenant.tenant;

export default slice.reducer;
