import AsanaIcon from './components/AsanaIcon';
import resources from './i18n';

export const rootPath = '/asana';

const config = {
  name: 'asana',
  rootPath,
  i18n: {
    resources,
  },
  menu: {
    getSections: (t) => [
      {
        title: t('modules.asana.title'),
        acl: [
          'api::asana.asana-template.find',
        ],
        items: [
          {
            module: 'asana',
            title: t('modules.asana.menu.create'),
            path: `${rootPath}`,
            type: 'ComponentTenantModuleIntegrationAsana',
            icon: <AsanaIcon />,
            acl: [
              'api::asana.asana-template.find',
            ],
          },
        ],
      },
    ],
  },
};

export default config;
