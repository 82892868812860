import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: '',
  subtitle: '',
};

const slice = createSlice({
  name: 'navbarTitle',
  initialState,
  reducers: {
    setTitle(state, action) {
      state.title = action.payload?.title ?? '';
      state.subtitle = action.payload?.subtitle ?? '';
    },
  },
});

export const { setTitle } = slice.actions;

export const selectNavbarTitle = (state) => state.navbarTitle;

export default slice.reducer;
