import { ApolloClient, InMemoryCache } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import { RetryLink } from '@apollo/client/link/retry';
import getConfig from 'next/config';
import { getSession } from 'next-auth/react';
import isEqual from 'lodash/isEqual';
import { setContext } from '@apollo/client/link/context';
import { useMemo } from 'react';
import merge from 'deepmerge';

const { publicRuntimeConfig } = getConfig();

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient;

function createApolloClient() {
  const httpLink = new HttpLink({
    uri: `${publicRuntimeConfig.API_ENDPOINT}/graphql`,
  });
  const retryLink = new RetryLink();
  const authLink = setContext(async (_, { headers }) => {
    const session = await getSession();
    const newHeaders = { ...headers };

    if (session && session.accessToken) {
      newHeaders.authorization = `Bearer ${session.accessToken}`;
    }

    return {
      headers: newHeaders,
    };
  });
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      return;
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        const error = new Error('GraphQL error');
        error.payload = { message, locations, path };

        console.error(error);
        Sentry.captureException(error, error.payload);
      });
    }
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: authLink.concat(retryLink).concat(errorLink).concat(httpLink),
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(initialState = null) {
  // eslint-disable-next-line no-underscore-dangle
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    // eslint-disable-next-line no-param-reassign
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
