import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from '@mui/material/styles';
import { Inter } from 'next/font/google';
import merge from 'lodash/merge';
import { baseThemeOptions } from './base-theme-options';
import { darkThemeOptions } from './dark-theme-options';
import { lightThemeOptions } from './light-theme-options';

export const DEFAULT_FONT = Inter({
  subsets: ['latin'],
  display: 'swap',
});

export const createTheme = (config) => {
  if (config.fontFamily) {
    baseThemeOptions.typography.fontFamily = config.fontFamily;
  }

  let theme = createMuiTheme(
    baseThemeOptions,
    (config.mode === 'dark'
      ? merge(darkThemeOptions, config.brandThemeDark)
      : merge(lightThemeOptions, config.brandThemeLight)),
    {
      direction: config.direction,
    },
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
