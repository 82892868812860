import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  feedback: null,
};

const slice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setFeedback(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.feedback = action.payload;
    },
  },
});

export const { setFeedback } = slice.actions;

export const selectCurrentFeedback = (state) => state.feedback.feedback;

export default slice.reducer;
