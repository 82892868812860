import getConfig from 'next/config';
import { DateTime } from 'luxon';
import { useSession } from 'next-auth/react';
import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';

const { publicRuntimeConfig } = getConfig();

export function useDownloadFunction() {
  const { data: session } = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);

  const handler = useCallback(async (event) => {
    try {
      setLoading(true);
      const functionPdfUrl = `${publicRuntimeConfig.API_ENDPOINT}/api/events/${event.id}/function/download`;
      const response = await fetch(functionPdfUrl, {
        headers: {
          Authorization: `Bearer ${session.accessToken}`,
        },
      });

      const blob = await response.blob();
      const objectUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      document.body.appendChild(anchor);

      const eventDate = DateTime.fromISO(event.attributes.date)
        .setLocale('de')
        .toLocaleString(DateTime.DATE_SHORT);
      anchor.href = objectUrl;
      anchor.download = `${eventDate} Function VA-${event.attributes.externalReferenceId}.zip`;
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
      anchor.remove();

      enqueueSnackbar('Function heruntergeladen', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [
    session.accessToken,
    enqueueSnackbar,
    setLoading,
  ]);

  return [isLoading, handler];
}
