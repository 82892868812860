import { Box, CircularProgress } from '@mui/material';
import Logo from '@components/Logo';

export default function SplashScreen() {
  return (
    <Box
      id="splash-screen"
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'center',
        left: 0,
        p: 3,
        position: 'fixed',
        top: 0,
        width: '100vw',
        zIndex: 2000,
      }}
    >
      <CircularProgress
        size={60}
        sx={{
          opacity: 0.5,
          marginTop: 'auto',
          marginBottom: 'auto',
        }}
        variant="indeterminate"
      />
      <Logo
        height={42}
        width={121}
      />
    </Box>
  );
}
