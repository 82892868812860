import PropTypes from 'prop-types';
import { useModuleAccessibility } from '@modules/event/hooks';
import { usePermissions } from '@lib/hooks';
import { FiEyeOff } from 'react-icons/fi';
import { useTheme } from '@mui/material';

const REQUIRED_ACL = [
  'api::event-module-setting.event-module-setting.update',
];

export default function MenuItemIcon({ name, icon }) {
  const theme = useTheme();
  const permissions = usePermissions();
  const [accessibility] = useModuleAccessibility(name);
  const satisfiedPermissions = REQUIRED_ACL.filter(
    (permission) => permissions.includes(permission),
  );

  if (accessibility === 'inaccessible'
  && satisfiedPermissions.length === REQUIRED_ACL.length) {
    return <FiEyeOff style={{ color: theme.palette.warning.main }} />;
  }

  return icon;
}

MenuItemIcon.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.node,
};

MenuItemIcon.defaultProps = {
  icon: null,
};
