import { RiCalendarScheduleLine } from 'react-icons/ri';
import resources from './i18n';

export const rootPath = '/schedules';

const config = {
  name: 'schedules',
  rootPath,
  i18n: {
    resources,
  },
  modules: {
    ComponentTenantModuleSchedule: {},
  },
  menu: {
    getSections: (t) => [
      {
        title: t('modules.schedules.title'),
        acl: [
          'api::event.event.find',
        ],
        items: [
          {
            module: 'schedules',
            title: t('modules.schedules.menu.roomAllocation'),
            path: `${rootPath}/room-allocation`,
            type: 'ComponentTenantModuleSchedule',
            icon: <RiCalendarScheduleLine />,
            acl: [
              'api::event.event.isEmployee',
              'api::event.event.find',
            ],
          },
        ],
      },
    ],
  },
};

export default config;
