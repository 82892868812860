import {
  FiCalendar, FiMessageSquare, FiFile, FiClock,
} from 'react-icons/fi';
import resources from './i18n';

export const rootPath = '/events';

const config = {
  name: 'events',
  rootPath,
  i18n: {
    resources,
  },
  modules: {
    ComponentTenantModuleEventBasic: {
      navMenuItems: [
        {
          title: 'modules.event.title',
          attribute: 'navItemEvents',
        },
      ],
    },
  },
  menu: {
    getSections: (t) => [
      {
        title: t('modules.events.title'),
        acl: [
          'api::event.event.find',
        ],
        items: [
          {
            module: 'events',
            title: t('modules.events.menu.events'),
            path: `${rootPath}`,
            type: 'ComponentTenantModuleEventBasic',
            icon: <FiCalendar />,
            attribute: 'navItemEvents',
            acl: [
              'api::event.event.find',
            ],
          },
          {
            module: 'allFeedback',
            title: t('modules.events.menu.feedback'),
            path: `${rootPath}/feedback`,
            type: 'ComponentTenantModuleEventFeedback',
            icon: (
              <span style={{
                width: 20,
                height: 20,
                position: 'relative',
              }}
              >
                <FiMessageSquare style={{ position: 'absolute', zIndex: 1 }} />
                <FiMessageSquare style={{
                  position: 'absolute',
                  zIndex: 2,
                  bottom: -4,
                  right: -4,
                  clipPath: 'polygon(100% 0%, 66% 0%, 66% 54%, 0% 54%, 0% 100%, 100% 100%)',
                }}
                />
              </span>
            ),
            acl: [
              'api::event.event.isEmployee',
              'api::event-feedback.event-feedback-note.find',
            ],
          },
          {
            module: 'reports',
            title: t('modules.events.menu.reports'),
            path: `${rootPath}/reports`,
            type: 'ComponentTenantModuleReport',
            icon: <FiFile />,
            acl: [
              'api::event.event.isEmployee',
              'api::report.report.find',
            ],
          },
          {
            module: 'eventSchedule',
            title: t('modules.events.menu.eventSchedule'),
            path: `${rootPath}/event-schedule`,
            type: 'ComponentTenantModuleEventSchedule',
            icon: <FiClock />,
            acl: [
              'api::event.event.isEmployee',
              'api::event-schedule.event-schedule.find',
            ],
          },
        ],
      },
    ],
  },
};

export default config;
